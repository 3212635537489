import { useState } from "react";
import { useFloating, offset, flip, shift } from "@floating-ui/react";
import PublishDropdownButtonContent from "~/components/general/PublishDropdownButtonContent";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";
import { useRouteLoaderData } from "@remix-run/react";
import { loadTranslator } from "~/utils/loadTranslator";

interface Props {
  communityNameForPublish?: string;
}

const PublishDropdownButton = ({ communityNameForPublish }: Props) => {
  const t = loadTranslator(useRouteLoaderData("root").translations)

  const [isOpen, setIsOpen] = useState(false);
  const { x, y, reference, floating, strategy } = useFloating({
    middleware: [offset(10), flip(), shift()],
    placement: "top",
    strategy: "fixed"
  });

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  useOnRouteChange(() => setIsOpen(false));

  return (
    <div className="relative hidden rounded-full overflow-hidden pc:flex pc:w-full pc:px-4 pc:mt-1">
      <button
        ref={reference}
        onClick={handleClick}
        className="flex flex-1 m-0 p-0 pc:mr-0 pc:px-4 pc:py-2.5 text-center justify-center pc:justify-center rounded-full text-pri font-semibold pc:hover:bg-acc/[.85] pc:bg-acc pc:dark:hover:bg-acc/[0.85] pc:dark:bg-acc"
      >
        <span className="hidden pc:flex text-base">{t("publish")}</span>
      </button>
      {isOpen && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0
          }}
          className="w-[calc(100%-48px)]
            flex items-center justify-center sm:justify-start 
            bg-pri dark:bg-pri-d border-pri dark:border-pri-d border rounded-xl 
            p-1 outline-none drop-shadow-md shadow-[0_0_12px_3px_rgb(255_255_255_/_20%)]"
        >
          <PublishDropdownButtonContent
            communityNameForPublish={communityNameForPublish}
            setIsOpen={setIsOpen}
          />
        </div>
      )}
    </div>
  );
};

export default PublishDropdownButton;

