import React from "react";
import { useAppStore } from "~/store";
import { loadHiveClient, loadHivePrivateKey } from "~/utils/hive";

export function useAutoHBDConvert(enabled?: boolean) {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const keys = useAppStore(store => store.account.keys);

  React.useEffect(() => {
    if (enabled === false) return;
    if (!activeAccount) return;

    let timeout = setTimeout(async () => {
      if (!keys.phrase) return;

      const dhive = await loadHiveClient();
      const PrivateKey = await loadHivePrivateKey();
      const private_key = PrivateKey.fromString(keys.activeKey);

      const [accountData] = await dhive.database.call("get_accounts", [[activeAccount.name]]);

      let is_balance_equal = +accountData?.hbd_balance?.replaceAll("HBD", "") >= 1;
      if (is_balance_equal) {
        const hive_transaction = {
          from: activeAccount?.name,
          to: "leodex",
          amount: accountData.hbd_balance,
          memo: `from:${activeAccount?.name}:to:${activeAccount?.name}:chain:HIVE:asset:LEO`
        };

        await dhive.broadcast.transfer(hive_transaction, private_key);
      }
    }, 75_000);

    return () => clearTimeout(timeout);
  }, [activeAccount, keys, enabled]);
}
