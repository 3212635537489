import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "@remix-run/react";
import { s } from "hastscript";
import { useEffect, useState } from "react";
import { useAppStore } from "~/store";
import { type HiveContent, callHiveClient, fetchPosts } from "~/utils/hive";
import { hivesigner } from "~/utils/hivesigner";
import { KeychainKeyType } from "~/utils/keychain";
import { AccountSession, broadcastAll } from "~/utils/transactions";

export function Announcements() {
  const navigate = useNavigate();
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const session = useAppStore(store => store.account.keys);


  /*
  const [announcement, setAnnouncement] = useState<HiveContent[] | false>(
    false
  );
  */
  const [visible, setVisible] = useState<boolean>(false);
  const [voted, setVoted] = useState(false)

  useEffect(() => {
    void (async function () {
      if (!activeAccount) return;
      console.log(activeAccount?.name)
      const proposals = await callHiveClient("database_api", "list_proposal_votes", {
        "start": [
            332,
            activeAccount?.name
        ],
        "limit": 1,
        "order": "by_proposal_voter",
        "order_direction": "ascending",
        "status": "all"
    }, "https://hive.inleo.io");
    
      

    const isLeoProposalVoted = proposals?.proposal_votes?.at(0).proposal?.id === 332 &&  proposals?.proposal_votes?.at(0).voter === activeAccount?.name
      isLeoProposalVoted === false && setVisible(true)
      /*
      let announcement = await fetchPosts("leofinance", 1, null, null);

      setAnnouncement(announcement);

      const announcementFromStorage =
        window.localStorage.getItem("announcement_id");
      const announcementReaded = window.localStorage.getItem(
        "announcement_readed"
      );

      // check if same announcement
      if (
        announcementFromStorage &&
        announcement[0].permlink === announcementFromStorage &&
        announcementReaded === "1"
      )
        return;

      setVisible(true);
      window.localStorage.setItem("announcement_id", announcement[0].permlink);
      */
    })();
  }, [activeAccount]);

  // handle announcement visibility
  const closeAnnouncement = (redirect: boolean) => {
    window.localStorage.setItem("announcement_readed", "1");
    setVisible(false);


    if (redirect) {
      window.location.href = 'https://peakd.com/me/proposals/332';
    }
  };


  const voteForProposal = async () => {

    const operation = [
      "update_proposal_votes",
      {
        voter: activeAccount?.name,          
        proposal_ids: [332],        
        approve: true,
        extensions: []
      }
    ];


    
    await broadcastAll({
      author: activeAccount?.name,
      hivesignerClient: hivesigner,
      type: KeychainKeyType.Active,
      operations: [operation],
      session
    });

    setVoted(true)
    setTimeout(() => {
      setVisible(false)
    }, 4000)
  }

  if (!visible || !activeAccount) return null;
  return (
    <div className="fixed bottom-24 left-4 pc:bottom-6 !z-[9999] pc:right-6 pc:left-[initial] flex flex-col max-w-[340px] p-5 bg-gray-100 dark:bg-zinc-800 border border-pri dark:border-pri-d rounded-lg">
      <div className="flex w-full justify-between items-center gap-x-5 pb-3">
        <strong className="text-lg font-semibold">
          Your Vote Matters
        </strong>
        <button
          className="flex items-center justify-center w-8 h-8 text-sm rounded-full hover:bg-gray-200 dark:hover:bg-zinc-700 transition-colors duration-150"
          onClick={() => closeAnnouncement(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div  className="flex flex-col  gap-y-4">
        <p className="line-clamp-4  text-sm text-gray-500 dark:text-zinc-400">
          {
          //announcement[0].title
          }
          {
            voted ? "Successfully voted! Thank you for supporting INLEO in our mission to grow the Hive ecosystem. We appreciate your support. 🦁" : <>
              INLEO's DHF Proposal is now live. We are requesting funding to onboard 10 more ecosystems like Dash for our "Join INLEO, Earn X" Campaigns.
              <div className="flex flex-row p-4 gap-5 w-full justify-between align-center">
                <div className="h-6 w-6 rounded-full ">
                  <img src="https://docs.mayaprotocol.com/~gitbook/image?url=https%3A%2F%2F52506229-files.gitbook.io%2F%7E%2Ffiles%2Fv0%2Fb%2Fgitbook-x-prod.appspot.com%2Fo%2Fspaces%252FxmlRc4VVlbjfKRL9k4Cw%252Ficon%252FpY6R8CCIHm0jNCFEww4n%252FBlue_Icon_Maya.png%3Falt%3Dmedia%26token%3Db95b05b4-466d-4b31-bb01-1d0b226a2a41&width=32&dpr=1&quality=100&sign=8f26892c&sv=2"/>
                </div>
                <div className="h-6 w-6 rounded-full overflow-hidden">
                  <img src="https://media.dash.org/wp-content/uploads/Dash-D-white_on_blue_circle.svg"/>
                </div>
                <div className="h-6 w-6 rounded-full overflow-hidden">
                  <img src="https://leodex.io/_next/image?url=%2Fassets%2Farb.webp&w=256&q=100"/>
                </div>
                <div className="h-6 w-6 rounded-full bg-black flex items-center">
                  <img src="https://seeklogo.com/images/S/solana-sol-logo-12828AD23D-seeklogo.com.png"/>
                </div>
                <div className="h-6 w-6 rounded-full bg-black overflow-hidden  flex items-center justify-center">
                  <img className="bg-black w-3 h-3" src="https://chainflip.io/images/token/Chainflip.svg"/>
                </div>
                <div className="h-6 w-6 rounded-full overflow-hidden flex items-center justify-center">
                  <img  src="https://images-ext-1.discordapp.net/external/C3Mshh8E0yrDFVQX8B3_J5QtKcY0jYj8TlCMyhhmNF0/https/cryptologos.cc/logos/chainlink-link-logo.png?format=webp&quality=lossless&width=908&height=908"/>
                </div>
              </div>
            </> 
          }

        </p>

        { voted === false &&
        <div className="flex flex-row items-center gap-x-1">
          <button
            type="button"
            className="py-2 px-4 text-xs font-medium bg-acc text-pri rounded-full hover:bg-acc-hov transition-colors duration-150"
            onClick={() => voteForProposal()}
          >
            Vote
          </button>
          <button
            type="button"
            className="py-2 px-4 text-xs font-medium border border-acc text-acc rounded-full hover:bg-acc-hov hover:border-acc-hov hover:text-pri transition-colors duration-150"
            onClick={() => closeAnnouncement(true)}
          >
            Read Proposal
          </button>
        </div>
        }
      </div>
    </div>
  );
}
function at(arg0: number) {
  throw new Error("Function not implemented.");
}

