import {
  autoPlacement,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from "@floating-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";
import { ScrollArea } from "./ScrollArea";
import { useAppStore } from "~/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { cn } from "~/utils/cn";
import { prepareAuthorization, updateInfraSettings } from "~/utils/infra";
import { setCookie } from "~/utils/cookie";
import { Spinner } from "./format/Spinner";

const LOCALE_OPTIONS = [
  {
    label: "English (English)",
    value: "en"
  },
  {
    label: "Arabic (العربية)",
    value: "ar"
  },
  {
    label: "German (Deutsch)",
    value: "de"
  },
  {
    label: "Spanish (Español)",
    value: "es"
  },
  {
    label: "French (Français)",
    value: "fr"
  },
  {
    label: "Portuguese (Português)",
    value: "pg"
  },
  {
    label: "Russian (Русский)",
    value: "ru"
  },
  {
    label: "Japanese (日本語)",
    value: "jp"
  },
  {
    label: "Korean (한국어)",
    value: "kr"
  },
  {
    label: "Chinese (中文)",
    value: "cn"
  },
  {
    label: "Turkish (Türkçe)",
    value: "tr"
  },
  {
    label: "High-Valyrian (Valyrian)",
    value: "high-valerian"
  },
  {
    label: "Klingon (tlhIngan Hol)",
    value: "klingon"
  },
  {
    label: "Elvish (Quenya)",
    value: "elvish"
  },
  {
    label: "Pirate (Pirate)",
    value: "pirate"
  }
];

export default function LanguageDropdown({
  children,
  onFocusChange,
  align = "right"
}: {
  children: React.ReactNode;
  onFocusChange?: (focused: boolean) => void;
  align?: "top" | "right";
}) {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => setOpen(open),
    middleware: [offset(8), autoPlacement({ alignment: "end" })],
    placement: "top-end",
    strategy: "fixed"
  });

  const click = useClick(context, {
    event: "click"
  });

  const role = useRole(context, { role: "menu" });
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

  const activeAccount = useAppStore(store => store.account.activeAccount);
  const keys = useAppStore(store => store.account.keys);
  const settings = useAppStore(store => store.settings);
  const currentLocale = settings.locale;

  const [revalidating, setRevalidating] = React.useState<boolean>(false);

  useEffect(() => {
    onFocusChange?.(open);
  }, [open]);

  useOnRouteChange(() => {
    setOpen(false);
  });

  async function changeLocale(locale: string) {
    if (currentLocale === locale) return;

    setRevalidating(true);

    if (activeAccount) {
      const authorization = await prepareAuthorization(activeAccount);

      await updateInfraSettings(activeAccount.name, {
        settings: {
          ...settings,
          locale
        },
        authorization: {
          ...authorization,
          hivesigner: keys.proxy === "hivesigner"
        }
      });

      setTimeout(() => {
        setRevalidating(false);
        setOpen(false);
        window.location.reload();
      }, 1000);
    } else {
      setCookie("inleo-locale", locale);

      setTimeout(() => {
        setRevalidating(false);
        setOpen(false);
        window.location.reload();
      }, 1000);
    }
  }

  return (
    <Fragment>
      <div ref={reference} {...getReferenceProps} onClick={() => setOpen(current => !current)}>
        {children}
      </div>
      <FloatingPortal>
        {open && (
          <div
            className="flex flex-col justify-start items-start bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-xl px-1.5 py-1.5 pc:py-1.5 pc:px-1.5 text-xs z-[1000] overflow-hidden drop-shadow-md min-w-[160px] sm:min-w-[200px] shadow-[0_0_12px_3px_rgb(255_255_255_/_17%)]"
            ref={floating}
            style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
            {...getFloatingProps()}
            tabIndex={-1}
          >
            <div className="relative flex flex-col w-full h-full">
              {revalidating ? (
                <div className="absolute inset-0 size-full bg-black/80 flex justify-center items-center z-10">
                  <Spinner className="size-5" />
                </div>
              ) : null}

              <ScrollArea
                className={cn("w-full h-48", {
                  "cursor-not-allowed": revalidating
                })}
              >
                {LOCALE_OPTIONS.map(locale => (
                  <button
                    key={locale.value}
                    type="button"
                    className={cn(
                      "flex flex-1 w-full justify-start items-center py-2.5 px-2.5 gap-x-2 text-start text-sm font-medium rounded-lg text-pri/90 dark:text-pri-d/90 hover:bg-pri-d/5 dark:hover:bg-pri/5",
                      {
                        "text-pri dark:text-pri-d bg-pri-d/5 dark:bg-pri/5": currentLocale === locale.value
                      }
                    )}
                    onClick={() => changeLocale(locale.value)}
                    disabled={currentLocale === locale.value}
                  >
                    {currentLocale === locale.value ? <FontAwesomeIcon icon={faCheck} size="sm" /> : null}
                    {locale.label}
                  </button>
                ))}
              </ScrollArea>
            </div>
          </div>
        )}
      </FloatingPortal>
    </Fragment>
  );
}
