import { Link } from "@remix-run/react";
import classNames from "classnames";
import { useState } from "react";
import useScrollDirection from "~/hooks/useScrollDirection";
import HamburgerMenu from "./HamburgerMenu";
import Logo from "./Logo";
import { SearchBarInitiator } from "./SearchBarInitiator";
import LanguageDropdown from "./LanguageDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAsia } from "@fortawesome/free-solid-svg-icons";

export default function AppHeader() {
  const scrollDirection = useScrollDirection(200);
  const [hamburgerMenuVisible, setHamburgerMenuVisible] = useState(false);

  return (
    <header
      className={classNames(
        "fixed flex sm:hidden justify-between items-center top-0 left-0 w-full h-[48px] z-[1001] px-4 py-3 bg-pri dark:bg-pri-d border-b border-pri dark:border-pri-d transition-transform duration-150",
        {
          "-translate-y-full": scrollDirection === "down",
          "translate-y-0": scrollDirection === "up"
        }
      )}
    >
      <Link
        prefetch="intent"
        className="sm:block w-40 inline-flex items-center select-none"
        aria-label="Threads"
        to="/threads"
      >
        <Logo />
      </Link>

      <div className="relative flex items-center gap-x-3">
        <LanguageDropdown>
          <button className="flex justify-center items-center size-6 relative text-acc mt-px" type="button">
            <FontAwesomeIcon icon={faEarthAsia} className="text-base" />
          </button>
        </LanguageDropdown>
        <SearchBarInitiator minimized />
        <HamburgerMenu visibility={hamburgerMenuVisible} setVisibility={setHamburgerMenuVisible} />
      </div>
    </header>
  );
}
